import React, { useContext, useEffect, useCallback, useState } from 'react'
import axios from 'axios'
import { CircularProgress } from '@material-ui/core'

import Container from './styles'
import { Store } from 'store'
import { Button } from 'components/Button'

export default ({ history }) => {
  const [state] = useContext(Store)
  const { token, image } = state
  const [loading, setLoading] = useState(true)
  const [result, setResult] = useState(null)
  const [error, setError] = useState('')

  const getEvaluation = useCallback(async () => {
    try {
      const res = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/evaluate?detailed=1`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token.value,
        },
      })

      if (res.status !== 200) {
        if (res.status > 400) {
          setError('Houve um erro ao processar os resultados')
          setLoading(false)
        } else {
          setTimeout(getEvaluation, 1000)
        }
      } else {
        setResult(res.data)
        setLoading(false)
      }
    } catch (e) {
      setError('Houve um erro ao processar os resultados')
      setLoading(false)
    }
  }, [token.value])

  useEffect(() => {
    getEvaluation()
  }, [getEvaluation])

  const handleRestart = () => {
    history.replace('/token')
  }

  return (
    <React.Fragment>
      <h1>Resultado</h1>

      <Container>
        {loading && (
          <div className="loading">
            <p>Aguarde, calculando score...</p>
            <CircularProgress className="loadingLoader" />
          </div>
        )}
        <div className="body">
          {!loading && result && (
            <React.Fragment>
              <div className="screenshotContainer">
                <img className="screenshot" src={image.image} alt="" />
              </div>
              <div className="results">
                <div className="progressBar">
                  <div
                    className={`progressValue ${
                      result.approved ? 'approvedBack' : 'reprovedBack'
                    }`}
                    style={{ width: `${result.total}%` }}
                  />
                </div>
                <div
                  className={`result ${
                    result.approved ? 'approved' : 'reproved'
                  } `}
                >
                  <p>{result.approved ? 'Aprovado' : 'Reprovado'}</p>
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
        {!loading && error && <p className="error reproved">{error}</p>}
        {!loading && (
          <div className="buttonContainer">
            <Button onClick={handleRestart}>Reiniciar</Button>
          </div>
        )}
      </Container>
    </React.Fragment>
  )
}
