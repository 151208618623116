import React, { useState, useEffect, useContext, useCallback } from 'react'
import axios from 'axios'

import Container from './styles'
import { Store } from 'store'
import { SET_TOKEN } from 'store/reducers/tokenReducer'
import journeymask from 'assets/mask.jpg'
import journeylight from 'assets/light.jpg'
import journeyhat from 'assets/hat.jpg'

export default function ({ history }) {
  const [{ token }, dispatch] = useContext(Store)
  const [loaded, setLoaded] = useState(false)
  const [index, setIndex] = useState(0)

  const claimInitialToken = useCallback(async () => {
    const res = await axios({
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/token`,
      headers: {
        Authorization: 'Bearer ' + token.value,
        'Content-Type': 'application/json',
      },
    })

    if (res.data) {
      setLoaded(true)
      dispatch({ type: SET_TOKEN, payload: res.data.token })
    }
  }, [dispatch, token])

  useEffect(() => {
    if (!loaded) claimInitialToken()
  }, [claimInitialToken, loaded])

  const handleChangeIndex = (i) => {
    setIndex(i)
  }

  const handleNextIndex = () => {
    if (index === 2) return
    setIndex(index + 1)
  }

  const handleInit = () => {
    return history.push('/liveness')
  }

  return (
    <Container>
      <div className="pagination">
        <span
          className={`dot ${index === 0 ? 'active' : ''}`}
          onClick={() => handleChangeIndex(0)}
        />
        <span
          className={`dot ${index === 1 ? 'active' : ''}`}
          onClick={() => handleChangeIndex(1)}
        />
        <span
          className={`dot ${index === 2 ? 'active' : ''}`}
          onClick={() => handleChangeIndex(2)}
        />
      </div>

      <div className="body">
        <div className={`screen ${index === 0 ? 'active' : ''}`}>
          <h3>Para garantir o melhor resultado</h3>
          <p>
            Levante a câmera até o nível dos seus olhos e mantenha seu rosto
            dentro da área marcada
          </p>
          <img src={journeymask} alt="" className="journey-img" />
        </div>

        <div className={`screen ${index === 1 ? 'active' : ''}`}>
          <h3>Use uma boa iluminação</h3>
          <p>
            Escolha um local com boa iluminação e mantenha seu rosto totalmente
            visível. Evite pontos fortes de luz, como um lâmpada ou sol atrás de
            você.
          </p>
          <img src={journeylight} alt="" className="journey-img" />
        </div>

        <div className={`screen ${index === 2 ? 'active' : ''}`}>
          <h3>Retire acessórios</h3>
          <p>Retire qualquer tipo de óculos e chapéus</p>
          <img src={journeyhat} alt="" className="journey-img" />
        </div>

        {/*<ul className="screens">
          <li className={`screen ${index === 0 ? 'active' : ''}`}>
            <h3>Para garantir o melhor resultado</h3>
            <p>
              Levante a câmera até o nível dos seus olhos e mantenha seu rosto
              dentro da área marcada
            </p>
            <img src={journeymask} alt="" className="journey-img" />
          </li>
          <li className={`screen ${index === 1 ? 'active' : ''}`}>
            <h3>Use uma boa iluminação</h3>
            <p>
              Esolha um local com boa iluminação e mantenha seu rosto totalmente
              visível. Evite pontos fortes de luz, como um lâmpada ou sol atrás
              de você.
            </p>
            <img src={journeylight} alt="" className="journey-img" />
          </li>
          <li className={`screen ${index === 2 ? 'active' : ''}`}>
            <h3>Retire acessórios</h3>
            <p>Retire qualquer tipo de óculos e chapéus</p>
            <img src={journeyhat} alt="" className="journey-img" />
          </li>
  </ul>*/}
      </div>

      <div className="footer">
        {index < 2 && (
          <button className="button next-screen" onClick={handleNextIndex}>
            Próximo
          </button>
        )}
        {index === 2 && (
          <button className="button finish" onClick={handleInit}>
            Iniciar
          </button>
        )}
      </div>
    </Container>
  )
}
