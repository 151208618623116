import tokenReducer from './tokenReducer'
import imageReducer from './imageReducer'
import callbackReducer from './callbackReducer'

const rootReducer = (state, action) => ({
  token: tokenReducer(state.token, action),
  image: imageReducer(state.image, action),
  callback: callbackReducer(state.callback, action),
})

export default rootReducer
