import tinycolor from 'tinycolor2'
import { createMuiTheme } from '@material-ui/core/styles'

const baseColor = '#cb184b'

export default {
  colors: {
    primary: baseColor,
    light: tinycolor(baseColor).lighten().toString(),
    dark: tinycolor(baseColor).darken().toString(),
    lightGray: '#ededed',
    gray: '#9c9c9c',
    darkGray: '#5c5c5c',
    error: '#e00b0b',
    green: 'green',
    yellow: 'yellow',
    red: 'red',
  },
  gray: {
    50: '#fafafa',
    100: '#f5f5f5',
    200: '#eeeeee',
    300: '#e0e0e0',
    400: '#bdbdbd',
    500: '#9e9e9e',
    600: '#757575',
    700: '#616161',
    800: '#424242',
    900: '#212121',
    A100: '#d5d5d5',
    A200: '#aaaaaa',
    A400: '#303030',
    A700: '#616161',
  },
  text: {
    primary: baseColor,
    contrast: tinycolor(baseColor).isDark() ? '#ffffff' : '#444444',
  },
  breakpoints: {
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
    xxl: '1600px',
  },
}

export const materialTheme = createMuiTheme({
  palette: {
    primary: {
      main: baseColor,
    },
  },
  typography: {
    htmlFontSize: 10,
    fontSize: 14,
  },
})
