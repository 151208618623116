import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useContext,
} from 'react'
import Webcam from 'react-webcam'
import CircularProgress from '@material-ui/core/CircularProgress'

import Container from './styles'
import { getFullFaceDescription } from 'api/face'
import { image64toCanvasRef } from 'utils/imageFns'
import { Store } from 'store'
import { postFile } from 'api/api'
import { SET_IMAGE } from 'store/reducers/imageReducer'

export default function ({ history }) {
  const cameraRef = useRef()
  const canvasRef = useRef(null)
  const [detectMessage, setDetectMessage] = useState(
    'Iniciando detecção facial',
  )
  const [loading, setLoading] = useState(false)
  const [{ token, callback }, dispatch] = useContext(Store)
  const [cameraStarted, setCameraStarted] = useState(false)
  const [validFace, setValidFace] = useState(false)

  const getFrame = useCallback(
    async (screenshot, faceBox) => {
      const canvas = canvasRef.current
      let formData = new FormData()
      let file = null

      await image64toCanvasRef(canvasRef.current, screenshot, faceBox)
      const croppedImage = canvas.toDataURL('image/jpeg', 0.9)

      dispatch({ type: SET_IMAGE, payload: screenshot })

      await fetch(croppedImage)
        .then((res) => res.blob())
        .then((blob) => {
          file = new File([blob], `liveness_photo.jpeg`, {
            type: 'image/jpeg',
          })
          formData.append('file', file)
        })

      setLoading(true)

      const res = await postFile({
        url: '/frame',
        token: token.value,
        data: formData,
        history,
      })

      if (res) {
        if (callback && callback.url) {
          let url = callback.url
          if (callback.url.indexOf('?') > -1)
            url = `${callback.url}&token=${res.token}`
          else url = `${callback.url}?token=${res.token}`
          window.location.replace(url)
        } else {
          return history.push('/evaluation')
        }
      }
    },
    [history, token.value, callback],
  )

  const findFace = useCallback(async () => {
    return new Promise(async (resolve) => {
      if (cameraRef.current && cameraRef.current.getScreenshot()) {
        const screenshot = cameraRef.current.getScreenshot()
        await getFullFaceDescription(screenshot).then(async (fullDesc) => {
          if (fullDesc) {
            setValidFace(true)
            getFrame(screenshot, fullDesc._box)
            resolve(true)
          }

          resolve(false)
        })
      } else {
        setValidFace(false)
        resolve(false)
      }
    })
  }, [getFrame])

  const main = useCallback(async () => {
    if (cameraRef.current && cameraRef.current.getScreenshot()) {
      await getFullFaceDescription(cameraRef.current.getScreenshot()).then(
        async (fullDesc) => {
          if (fullDesc) {
            const face_box = fullDesc._box

            if (face_box._width < 430) {
              setValidFace(false)
              setDetectMessage('Aproxime seu rosto até o círculo ficar VERDE')
              setTimeout(main, 300)
            } else if (face_box._width > 530) {
              setValidFace(false)
              setDetectMessage('Afaste seu rosto')
              setTimeout(main, 300)
            } else {
              setDetectMessage('')
              setValidFace(true)

              setTimeout(async () => {
                let descFound = false

                while (!descFound) {
                  descFound = await findFace()
                }
              }, 1000)
            }
          } else {
            setValidFace(false)
            setDetectMessage('Aguarde, detectando seu rosto...')
            setTimeout(main, 300)
          }
        },
      )
    } else {
      setValidFace(false)
      setDetectMessage('Aguarde, detectando seu rosto...')
      setTimeout(main, 300)
    }
  }, [findFace])

  useEffect(() => {
    main()
  }, [main])

  const handleCameraStart = () => {
    setCameraStarted(true)
  }

  return (
    <Container>
      <div className="cameraContainer">
        {cameraStarted && (
          <React.Fragment>
            <div className={`cameraOverlay ${!detectMessage && 'off'}`}>
              <span>{detectMessage}</span>
            </div>
            <div className={`loadingOverlay ${loading && 'on'}`}>
              <CircularProgress className="loadingLoader" />
            </div>
            <div className={`mask ${validFace ? 'valid' : ''}`} />
          </React.Fragment>
        )}
        <Webcam
          audio={false}
          ref={cameraRef}
          minScreenshotHeight={700}
          screenshotFormat="image/jpeg"
          videoConstraints={{ facingMode: 'user' }}
          onUserMedia={handleCameraStart}
        />
        <canvas ref={canvasRef} id="canvas"></canvas>
      </div>
    </Container>
  )
}
