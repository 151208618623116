export const SET_IMAGE = "SET_IMAGE"

export default (state, { type, payload }) => {
  switch (type) {
    case SET_IMAGE:
      return {
        ...state,
        image: payload
      }
    default:
      return state
  }
}
