import React, { useReducer, useEffect, useState } from "react"
import { ThemeProvider } from "styled-components"
import { ThemeProvider as MaterialProvider } from "@material-ui/styles"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import * as faceapi from "face-api.js"

import defaultTheme, { materialTheme } from "themes/default"
import { Store, initialState } from "store"
import rootReducer from "store/reducers/rootReducer"
import Routes from "config/routes"

function App() {
  const store = useReducer(rootReducer, initialState)
  const [modelsLoaded, setModelsLoaded] = useState(false)

  useEffect(() => {
    if (!modelsLoaded) {
      const MODEL_URL = process.env.PUBLIC_URL + "/models"
      // faceapi.loadTinyFaceDetectorModel(MODEL_URL)
      // faceapi.loadFaceRecognitionModel(MODEL_URL)

      Promise.all([
        faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL),
        faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL),
        faceapi.nets.faceRecognitionNet.loadFromUri(MODEL_URL),
        faceapi.nets.faceExpressionNet.loadFromUri(MODEL_URL),
      ]).then(() => setModelsLoaded(true))
    }
  }, [modelsLoaded])

  return (
    <Store.Provider value={store}>
      <ThemeProvider theme={defaultTheme}>
        <MaterialProvider theme={materialTheme}>
          <ToastContainer />
          <Routes />
        </MaterialProvider>
      </ThemeProvider>
    </Store.Provider>
  )
}

export default App
