import * as faceapi from "face-api.js"

// Loads models and weights
// export async function loadModels() {
//   const MODEL_URL = process.env.PUBLIC_URL + "/models"
//   await faceapi.loadTinyFaceDetectorModel(MODEL_URL)
//   // await faceapi.loadFaceLandmarkTinyModel(MODEL_URL)
//   await faceapi.loadFaceRecognitionModel(MODEL_URL)
// }

export async function getFullFaceDescription(blob) {
  // tiny_face_detector options
  let scoreThreshold = 0.5
  let inputSize = 512
  const OPTIONS = new faceapi.TinyFaceDetectorOptions({
    inputSize,
    scoreThreshold
  })

  let img = await faceapi.fetchImage(blob)
  let fullDesc = await faceapi.detectSingleFace(img, OPTIONS)
  return fullDesc
}
