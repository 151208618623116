import styled from "styled-components"

export default styled.button`
  background-color: ${props => props.theme.colors.primary};
  color: ${props => props.theme.text.contrast};
  border: 0;
  border-radius: 3rem;
  width: 100%;
  max-width: 33rem;
  font-size: 1.6rem;
  font-weight: 600;
  text-transform: uppercase;
  height: 5rem;
  outline: none;
  margin-top: 3rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  transition: all 0.3s ease;

  &:focus {
    outline: none;
  }

  &:active {
    background: linear-gradient(${props => props.theme.colors.primary}, ${props => props.theme.colors.dark});
  }

  &.disabled {
    background-color: ${props => props.theme.colors.lightGray};
    width: 5rem;
  }
`
