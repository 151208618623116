import React, { useContext } from "react"
import { Redirect } from "react-router-dom"
import { toast } from "react-toastify"

import { Store } from "store"

export default ({ parent: Parent, child, roles, ...rest }) => {
  const [{ token }] = useContext(Store)

  if (!token) {
    toast.error("Acesso não autorizado!")
    return <Redirect to="/" />
  }

  return <Parent component={child} {...rest} />
}
