export const SET_CALLBACK = 'SET_CALLBACK'

export default (state, { type, payload }) => {
  switch (type) {
    case SET_CALLBACK:
      return {
        ...state,
        url: payload,
      }
    default:
      return state
  }
}
