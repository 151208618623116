import React, { useContext, useState } from 'react'
import * as Yup from 'yup'
import { Formik, Field } from 'formik'
import axios from 'axios'
import { toast } from 'react-toastify'

import Container from './styles'
import { Button } from 'components/Button'
import { LoginTextInput } from 'components/LoginTextInput'
import { validationMessages } from 'utils/messages'
import { Store } from 'store'
import { SET_TOKEN } from 'store/reducers/tokenReducer'

const validationSchema = Yup.object().shape({
  username: Yup.string().required(validationMessages.required),
  password: Yup.string().required(validationMessages.required),
})

export default function ({ history }) {
  const [{ token }, dispatch] = useContext(Store)
  const [loading, setLoading] = useState(false)

  const initialValues = {
    username: '',
    password: '',
  }

  const handleSubmit = async () => {
    setLoading(true)

    try {
      const res = await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/auth`,
        headers: { 'Content-Type': 'application/json' },
        data: { username: token.username, password: token.password },
      })

      if (res) {
        dispatch({ type: SET_TOKEN, payload: res.data.token })
        setTimeout(() => history.push('/token'), 1000)
      }
    } catch (e) {
      toast.error(e.response.data.message)
      setLoading(false)
    }
  }

  return (
    <Container>
      <div className="panel">
        <header>
          <h1>Liveness</h1>
        </header>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          render={(form) => {
            return (
              <form onSubmit={form.handleSubmit}>
                <Field
                  name="username"
                  label="Nome de Usuário"
                  component={LoginTextInput}
                />
                <Field
                  name="password"
                  type="password"
                  label="Senha"
                  component={LoginTextInput}
                />
                <Button type="submit" loading={loading}>
                  Entrar
                </Button>
              </form>
            )
          }}
        />
      </div>
    </Container>
  )
}
