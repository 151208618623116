import styled from "styled-components"

export default styled.div`
  background-color: white;
  padding: 2rem;
  border-radius: 1.6rem;
  box-shadow: 0 3px 5px 1px rgba(0, 0, 0, 0.2);

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    width: 100%;
    margin-bottom: 2rem;
  }

  .loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: ${props => props.theme.colors.gray};

    p {
      font-size: 1.6rem;
    }

    .MuiCircularProgress-root {
      margin-top: 1rem;
    }
  }

  .body {
    display: flex;

    @media (max-width: ${props => props.theme.breakpoints.md}) {
      flex-direction: column;
    }
  }

  .screenshotContainer {
    .screenshot {
      border-radius: 0.8rem;
      width: 100%;
      object-fit: contain;

      @media (min-width: ${props => props.theme.breakpoints.sm}) {
        max-width: 40rem;
      }
    }
  }

  p {
    font-size: 2rem;
    margin-bottom: 0;
  }

  strong {
    font-size: 2rem;
  }

  .loadingLoader {
    color: ${props => props.theme.colors.gray};
  }

  .progressBar {
    height: 2rem;
    width: 20rem;
    background-color: ${props => props.theme.colors.lightGray};
    border-radius: 2rem;

    @media (max-width: ${props => props.theme.breakpoints.md}) {
      width: 100%;
    }
  }

  .progressValue {
    height: 2rem;
    border-radius: 2rem;
  }

  .approvedBack {
    background-color: ${props => props.theme.colors.green};
  }

  .reprovedBack {
    background-color: ${props => props.theme.colors.red};
  }

  .approved {
    color: ${props => props.theme.colors.green};
  }

  .reproved {
    color: ${props => props.theme.colors.red};
  }

  .results {
    margin-top: 3rem;

    @media (min-width: ${props => props.theme.breakpoints.md}) {
        margin-left: 2rem;
    }
  }

  .result {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
  }

  .valuesInfo {
    margin-top: 2rem;

    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      display: flex;
      justify-content: space-between;
    }

    p {
      font-size: 1.8rem;
      margin-bottom: 1rem;

      span {
        font-weight: bold;
        color: ${props => props.theme.colors.gray};
      }
    }
  }

  .error {
    width: 100%;
    text-align: center;
  }

  .buttonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
