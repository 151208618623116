import { createContext } from "react";

export const Store = createContext();

export const initialState = {
  username: "",
  password: "",
  value: "",
  steps: [],
};
