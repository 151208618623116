import styled from "styled-components"

export default styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;

    header {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      margin-bottom: 2rem;
      max-width: 80rem;

      .logo {
        padding: 0;
        width: 15rem;
      }
    }

    h1 {
      font-size: 2.4rem;
      margin: 2rem 0%;
      text-align: center;

      @media (min-width: ${props => props.theme.breakpoints.md}) {
        font-size: 2.8rem;
      }
    }
`
