import React, { useEffect, useCallback, useState, useContext } from 'react'
import * as QueryString from 'query-string'
import styled from 'styled-components'
import { CircularProgress } from '@material-ui/core'
import axios from 'axios'

import logo from 'assets/logo_small.svg'
import { SET_TOKEN } from 'store/reducers/tokenReducer'
import { SET_CALLBACK } from 'store/reducers/callbackReducer'
import { Store } from 'store'

const Container = styled.div`
  background-color: ${(props) => props.theme.gray[100]};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .loader {
    width: 30px;
    height: 30px;

    svg {
      color: ${(props) => props.theme.gray[300]};
    }
  }

  .error_text {
    font-size: 16px;
    font-weight: 600;
    color: ${(props) => props.theme.gray[500]};
  }

  .logo {
    width: 80px;
    margin-bottom: 20px;
  }
`

export default function ({ history, location }) {
  const [loading, setLoading] = useState(true)
  const [errorText, setErrorText] = useState('')
  const [_, dispatch] = useContext(Store)

  const fetchSignature = useCallback(async () => {
    const params = QueryString.parse(location.search)

    if (!params.signature) {
      setLoading(false)
      setErrorText('Assinatura não encontrada!')
      return false
    }

    try {
      const res = await axios({
        method: 'GET',
        url: `${
          process.env.REACT_APP_API_URL
        }/token?signature=${encodeURIComponent(params.signature)}`,
        headers: {
          'Content-Type': 'application/json',
        },
      })

      dispatch({ type: SET_TOKEN, payload: res.data.token })
      if (params.callback)
        dispatch({ type: SET_CALLBACK, payload: params.callback })

      setTimeout(() => {
        return history.push('/token')
      }, 1000)
    } catch (e) {
      setLoading(false)

      if (e.response) {
        setErrorText(e.response.data.message)
      } else {
        setErrorText('Houve um erro ao realizar esta ação')
      }
    }
  }, [history, location.search, dispatch])

  useEffect(() => {
    fetchSignature()
  }, [fetchSignature])

  return (
    <Container>
      {loading ? (
        <CircularProgress className="loader" />
      ) : (
        <React.Fragment>
          <img src={logo} alt="InovaMind" className="logo" />
          <div className="error_text">{errorText}</div>
        </React.Fragment>
      )}
    </Container>
  )
}
