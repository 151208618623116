import styled from "styled-components"

export default styled.div`
  width: 100%;
  max-width: 45rem;
  background-color: white;

  .panel {
    padding: 2rem;
    width: 100%;
    margin-top: 4rem;

    header {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      h1 {
        font-size: 4rem;
        margin: 0;
      }
    }

    form {
      margin-top: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
`
