import React, { useContext } from "react"
import FormControl from "@material-ui/core/FormControl"
import FormHelperText from "@material-ui/core/FormHelperText"
import Input from "@material-ui/core/Input"
import InputLabel from "@material-ui/core/InputLabel"
import styled from "styled-components"

import { Store } from "store"
import { SET_FIELD } from "store/reducers/tokenReducer"

const StyledFormHelperText = styled(FormHelperText)`
  text-align: right !important;
`

export const LoginTextInput = props => {
  const [, dispatch] = useContext(Store)

  const { label, field, form } = props
  const { errors, touched, setFieldValue } = form
  const { name } = field

  function handleChange(value) {
    dispatch({ type: SET_FIELD, payload: { field: name, value } })
    setFieldValue(name, value)
  }

  return (
    <FormControl error={touched[name] && !!errors[name]} fullWidth>
      <InputLabel htmlFor="component-error">{label}</InputLabel>
      <Input {...props} {...field} onChange={e => handleChange(e.target.value)} />
      <StyledFormHelperText id="component-error-text">{touched[name] && errors[name]}</StyledFormHelperText>
    </FormControl>
  )
}
